<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="checkStatus">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Project</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Project"
              >
                <v-select
                  v-model="project"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="projectname"
                  :options="projectOption"
                  placeholder="None"
                  @input="handelclickproject($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Phase</label>
              <b-form-input v-model="phase" :disabled="true" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="plot no"
              >
                <label>Plot No</label>
                <label style="color: red !important">*</label>
                <b-form-input
                  v-model="plotno"
                  type="text"
                  placeholder="Enter Plot No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>

              <b-row>
                <b-col md="12">
                  <label>Plot Area(IN SQ. YARD)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="plot area in yrd"
                  >
                    <b-form-input
                      v-model="plotareayd"

                      placeholder="IN SQ. YARD"
                      @input="totalProjectYd('projectArea')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>Plot Area(IN SQ. MTR)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="plot area in mtr"
                  >
                    <b-form-input
                      v-model="plotareasqmtr"

                      placeholder="IN SQ. MTR"
                      @input="totalProjectMtr('projectArea')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <label>Plot Area(IN SQ. FEET)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="plot area in feet"
                  >
                    <b-form-input
                      v-model="plotareasqfeet"

                      placeholder="IN SQ. FEET"
                      @input="totalProjectFeet('projectArea')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>

              <b-row>
                <b-col md="12">
                  <label>SB UP AREA(IN SQ. YARD)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="super area in yrd"
                  >
                    <b-form-input
                      v-model="superarea_sqyds"
                      placeholder="IN SQ. YARD"
                      @input="totalProjectYd('superBuildArea')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>SB UP AREA(IN SQ. MTR)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="super area in mtr"
                  >
                    <b-form-input
                      v-model="superarea_sqmt"

                      placeholder="IN SQ. MTR"
                      @input="totalProjectMtr('superBuildArea')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>SB UP AREA(IN SQ. FEET)</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="super area in feet"
                  >
                    <b-form-input
                      v-model="superarea_sqfeet"

                      placeholder="IN SQ. FEET"
                      @input="totalProjectFeet('superBuildArea')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>

              <b-row>
                <b-col md="12">
                  <label>CARPET AREA(IN SQ. YARD)</label>
                  <b-form-input
                    v-model="carpetarea_sqyds"
                    placeholder="IN SQ. YARD"
                    @input="totalProjectYd('carpetArea')"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>CARPET AREA(IN SQ. MTR)</label>
                  <b-form-input
                    v-model="carpetarea_sqmt"
                    placeholder="IN SQ. MTR"
                    @input="totalProjectMtr('carpetArea')"

                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <label>CARPET AREA(IN SQ. FEET)</label>
                  <b-form-input
                    v-model="carpetarea_sqfeet"
                    placeholder="IN SQ. FEET"
                      @input="totalProjectFeet('carpetArea')"

                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group>
              <label>Length</label>
              <b-form-input
                v-model="length"

                placeholder="Enter Length"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Width</label>
              <b-form-input
                v-model="width"

                placeholder="Enter Width"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Plot Unit In</label>
                <v-select
                  v-model="unitname"
                  label="name"
                  :options="['Yard','Feet','Meter']"
                  placeholder="None"
                  @input="totalAmountcal"
                />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Rate per {{unitname}} for CP/Employee</label>
              <b-form-input
                v-model="rateperyard"

                  @input="totalAmountcal"
                placeholder="Enter Rate"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Rate per {{unitname}} for Franchise</label>
              <b-form-input
                v-model="franchiserate"
                placeholder="Enter Rate"
                  @input="totalAmountcal"

              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Rate per {{unitname}} for Customer</label>
              <b-form-input
                v-model="customer_plotrate"
                placeholder="Enter Rate"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>With Development</label>
                <v-select
                  v-model="withdevelopment"
                  label="name"
                  :options="['Yes','No']"
                  @input="handelDevelopment"
                  placeholder="None"
                />
            </b-form-group>
          </b-col>

           <b-col md="4">
            <b-form-group>
              <label>Plot Value</label>
              <b-form-input
                v-model="plotValue"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Plot Amount</label>
              <b-form-input
                v-model="plotamount"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Plot Value For Franchise</label>
              <b-form-input
                v-model="plotValueFr"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Plot Amount For Franchise</label>
              <b-form-input
                v-model="plotAmountFr"
                :disabled="true"
              />
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group>
              <label>Status</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Status"
              >
                <v-select
                  v-model="status"
                  label="name"
                  :options="['Vacant', 'Booked', 'Sold', 'Hold', 'Registered']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
                <b-form-textarea
                  v-model="remarks"
                  placeholder="Enter Remarks"
                />
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group>
              <label>Place facing </label>
              <v-select
                  v-model="placefacing"
                  label="name"
                  :options="['East','West','North ','South','East North Corner','East South Corner','West North Corner','West South Corner']
"
                  placeholder="None"
                />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>PLC Charge</label>
                <v-select
                  v-model="plccharges"
                  label="name"
                  :options="['Yes','No']"
                  placeholder="None"
                  @input="totalAmountcal"
                />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="plccharges == 'Yes'">
            <b-row>
                <b-col :md="cornerValue == 'Yes' || cornerphase == 'Yes' ? '3' : '6'">
                  <b-form-group>
                    <label>Corner Phase</label>
                    <v-select
                      v-model="cornerphase"
                      placeholder="None"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="['Yes', 'No']"
                      @input="cornerhandelclick($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" v-if="cornerphase == 'Yes'">
                  <b-form-group>
                    <label>Corner phase Value(%)</label>
                    <label style="color: red !important">*</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Corner Phase value"
                    >
                    <v-select
                      v-model="cornerphasevalue"
                      placeholder="None"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
                      @input="totalAmountcal"
                    />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" v-if="cornerphase == 'Yes'">
                  <b-form-group>
                    <label>Cornar Phase Amount</label>
                    <b-form-input
                      v-model="cornerAmount"
                      disabled
                    />
                  </b-form-group>
                 </b-col>
                 <b-col md="3" v-if="cornerphase == 'Yes'">
                  <b-form-group>
                    <label>Cornar Phase Amount (Franchise)</label>
                    <b-form-input
                      v-model="cornerAmountFr"
                      disabled
                    />
                  </b-form-group>
                 </b-col>
            </b-row>
          </b-col>

          <b-col md="12" v-if="plccharges == 'Yes'">
            <b-row>
              <b-col :md="gardenValue == 'Yes' || gardenphase == 'Yes' ? '3' : '6'">
                <b-form-group>
                  <label>Garden Phase</label>
                  <v-select
                    v-model="gardenphase"
                    placeholder="None"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="['Yes', 'No']"
                    @input="gardenhandelclick($event)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="gardenphase == 'Yes'">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Garden Phase value"
                  >
                    <label>Garden phase Value(%)</label>
                    <label style="color: red !important">*</label>
                    <v-select
                    v-model="gardenphasevalue"
                    placeholder="None"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
                    @input="totalAmountcal"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="gardenphase == 'Yes'">
                <b-form-group>
                  <label>Garden Phase Amount</label>
                  <b-form-input
                    v-model="gardenAmount"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="gardenphase == 'Yes'">
                <b-form-group>
                  <label>Garden Phase Amount (Franchise)</label>
                  <b-form-input
                    v-model="gardenAmountFr"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>


          </b-col>
          <b-col md="12" v-if="withdevelopment == 'No'">
          <b-row>
            <b-col :md="development == 'Yes' ? '3' : '6'">
              <b-form-group>
                <label>Development Charge extra</label>
                <v-select
                  v-model="development"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="['Yes', 'No']"
                  @input="handelDevelopment"
                />
              </b-form-group>
            </b-col>

              <b-col md="4" v-if="development == 'Yes'">
                <b-form-group>
                  <label>Development Charge (Per Sq. Yard)</label>
                  <b-form-input
                    v-model="developmentcharge"
                    @input="handelDevelopment"

                    placeholder="Enter Development Charge"
                  />
                </b-form-group>
              </b-col>
                  <b-col md="4" v-if="development == 'Yes'">
                    <b-form-group>
                      <label>Development Amount</label>
                      <b-form-input
                        v-model="developmentamount"
                        disabled
                        placeholder="Enter Development AMount"
                      />
                    </b-form-group>
                </b-col>
          </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col :md="maintanance_d == 'Yes' ? '3' : '6'">
                <b-form-group>
                  <label>Maintainance charge</label>
                  <v-select
                    v-model="maintanance_d"
                    placeholder="None"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="['Yes', 'No']"
                    @input="handelExtra"

                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="maintanance_d == 'Yes'">
                <b-form-group>
                  <label style="font-size:11px !important">Maintainance Amount</label>
                  <small>(Per Month)</small>
                  <b-form-input
                    v-model="maintainancecharge"
                    type="number"
                    placeholder="Enter Amount"
                    @input="handelExtra"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="maintanance_d == 'Yes'">
                <b-form-group>
                  <label>Months</label>
                  <b-form-input
                    v-model="months"
                    type="number"
                    placeholder="Enter Months"
                    @input="handelExtra"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="maintanance_d == 'Yes'">
                <b-form-group>
                  <label>Total Maintainance Charges</label>
                  <b-form-input
                    v-model="totalmaintainance"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col :md="clubhouse_d == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Club House Charges</label>
              <v-select
                v-model="clubhouse_d"
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Yes', 'No']"
                @input="handelExtra"

              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="clubhouse_d == 'Yes'">
            <b-form-group>
              <label>Club House Amount</label>
              <b-form-input
                v-model="clubHouse"
                type="number"
                placeholder="Enter Amount"
                @input="handelExtra"

              />
            </b-form-group>
          </b-col>
          <b-col :md="electricity_d == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Electricity Charges</label>
              <v-select
                v-model="electricity_d"
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Yes', 'No']"
                @input="handelExtra"

              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="electricity_d == 'Yes'">
            <b-form-group>
              <label>Electricity Amount</label>
              <b-form-input
                v-model="electricitycharges"
                    type="number"
                placeholder="Enter Amount"
                @input="handelExtra"


              />
            </b-form-group>
          </b-col>
           <b-col :md="othercharges_d == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Other Charge</label>
              <v-select
                v-model="othercharges_d"
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Yes', 'No']"
                @input="handelExtra"


              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="othercharges_d == 'Yes'">
            <b-form-group>
              <label>Other Charges(Amount)</label>
              <b-form-input
                v-model="otherextracharges"

                placeholder="Enter Amount"
                @input="handelExtra"
              />
            </b-form-group>
          </b-col>

           <b-col md="3">
            <b-form-group>
              <label>Extra Amount</label>
              <b-form-input
                v-model="extraamount"

                placeholder="Enter Amount"
                disabled
              />
            </b-form-group>
          </b-col>




          <!-- <b-col md="4">
            <b-form-group>
              <label>Direction</label>
              <v-select v-model="direction" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                :options="['North', 'South', 'West', 'East']" placeholder="none" />
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="4">
            <b-form-group>
              <label>North</label>
              <b-form-input v-model="North" placeholder="EnterNorth" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>South</label>
              <b-form-input v-model="South" placeholder="Enter South" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>East</label>
              <b-form-input v-model="East" placeholder="Enter East" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>West</label>
              <b-form-input v-model="West" placeholder="Enter West" />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-card-code title="Documentation" no-body />
          </b-col>
          <b-col md="12">
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th></th>
                  <th>Document Name</th>
                  <th>Document file</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in documents" :key="id">
                  <td>{{ id + 1 }}</td>
                  <td>
                    <b-form-input
                      v-model="item.name"
                      placeholder="Enter Document Name"
                    />
                  </td>
                  <td>
                    <b-form-file
                      v-model="item.attachment"
                      placeholder="Select Document"
                      drop-placeholder="Drop file here..."
                      @input="handleChange($event, 'plotmaster', id)"
                    />
                    <attachment :data="item.attachment" />
                  </td>
                  <td>
                    <b-button variant="outline-danger" @click="removeRow(id)">
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button
              variant="primary"
              @click="addRow"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-card-code title="Updated Amount" no-body />
          </b-col>
          <b-col md="12">
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Updated Plot Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in updatedrate" :key="id">
                  <td>{{ id + 1 }}</td>
                  <td>
                    <flat-pickr
                      v-model="item.date"
                      :disabled="id < rateLength"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        defaultDate: new Date(),
                      }"
                      :style="
                        id < rateLength
                          ? 'background-color: #efefef'
                          : 'background-color: transparent'
                      "
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model="item.amount"

                      placeholder="Enter Amount"
                      :disabled="id < rateLength"
                      @input="handleUpdatedAmount(id)"
                    />
                  </td>
                  <td>
                    <b-button
                      variant="outline-danger"
                      @click="removeRowPlot(id)"
                      v-if="id + 1 > rateLength"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <b-button
              v-if="rateLength + 1 > updatedrate.length"
              variant="primary"
              @click="addRowPlot"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button> -->
          </b-col>
          <!-- submit button -->
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              :disabled="flag"
              @click.prevent="checkStatus"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import flatPickr from "vue-flatpickr-component";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import Attachment from "../../../../components/Attechment.vue";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
  },
  data() {
    return {
      phase: "",
      project: "",
      plotno: "",
      plotareasqmtr: "",
      plotareayd: "",
      superarea_sqmt: "",
      superarea_sqyds: "",
      carpetarea_sqmt: "",
      carpetarea_sqyds: "",
      carpetarea_sqfeet: "",
      customer_plotrate:'',


      sqmeters: "",
      sqFeet: "",
      newSurveyNo: "",
      oldSurveyNo: "",
      status: "",
      towardsTheNorth: "",
      towardsTheSouth: "",
      towardsTheEast: "",
      towardsTheWest: "",
      registrationNo: "",
      registrationDate: new Date(),
      naorderno: "",
      village: "",
      taluka: "",
      state: "",
      country: "India",
      ifEdit: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      projectOption: [],
      gardenValue: "",
      cornerValue: "",
      gardenphase: "",
      gardenphasevalue: "",
      cornerphase: "",
      cornerphasevalue: "",
      East: "",
      West: "",
      South: "",
      North: "",
      developmentcharge: "",
      length: "",
      width: "",
      franchiserate:'',
      rateperyard: "",
      plotamount: "",

      plotareasqfeet: "",
      superarea_sqfeet: "",
      totalarea_sqfeet: "",

      development:'',
      clubhouse_d:'',
      maintanance_d:'',
      electricity_d:'',

      othercharges_d:'',

      clubHouse:'',
      maintainancecharge:'',
      totalmaintainance:'',
      months:'',
      electricitycharges:'',
      otherextracharges:'',


      plccharges:'',
      placefacing:'',
      extraamount:'',
      withdevelopment:'',

      developmentamount:'',

      plotValue:'',

      unitname:"Yard",

      documents: [
        {
          name: "",
          attachment: "",
        },
      ],
      flag: false,
      updatedrate: [
        {
          date: moment(new Date()).format("DD/MM/YYYY"),
          amount: "",
        },
      ],
      rateLength: 0,
      cornerAmount:'',
      gardenAmount: '',
      plotAmountFr: 0,
      plotValueFr: 0,

      gardenAmountFr:0,
      cornerAmountFr: 0,
      remarks: '',
      oldStatus:''

    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Plot") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/plotmaster");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/plotmaster");
          }
        }
      });
    }
    this.getProject();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handelDevelopment(){
      if(this.development == 'Yes' && this.withdevelopment=='No'){
        this.developmentamount=this.plotareayd * this.developmentcharge;
        this.developmentamount = isNaN(this.developmentamount)?0:this.handleRoundOff(this.developmentamount)
        this.handelExtra()
        return;
      }
      if(this.development == 'No'){
        this.developmentamount = null
        this.developmentcharge = null
      }
      this.handelExtra()

      this.developmentcharge=0
      this.developmentamount=0
      this.development=''
    },
    handelExtra(){
      this.extraamount=0

      if(this.development == 'Yes'){
        this.extraamount+=this.developmentamount?parseFloat(this.developmentamount):0
      }
      if(this.clubhouse_d == 'Yes'){
        this.extraamount+=this.clubHouse?parseFloat(this.clubHouse):0
      }else{
        this.clubHouse = null
      }
      if(this.maintanance_d=='Yes'){
        this.totalmaintainance = this.handleRoundOff(this.months * (this.maintainancecharge))

        this.extraamount+=this.totalmaintainance?parseFloat(this.totalmaintainance):0
      }else{
        this.maintainancecharge = null
        this.months = null
        this.totalmaintainance =null
      }
      if(this.othercharges_d == 'Yes'){
        this.extraamount+=this.otherextracharges?parseFloat(this.otherextracharges):0
      }else{
        this.otherextracharges = null
      }
      if(this.electricity_d == 'Yes'){
        this.extraamount+=this.electricitycharges?parseFloat(this.electricitycharges):0
      }else{
        this.electricitycharges = null
      }

    },
    totalAmountcal () {

      if(this.cornerphase !== 'Yes' || (this.plccharges !== 'Yes')){
        this.cornerphasevalue = 0
        this.cornerphase="No"
      }
      if(this.gardenphase !== 'Yes' || this.plccharges !== 'Yes'){
        this.gardenphasevalue = 0
        this.gardenphase="No"

      }

      let plotAmount = 0
      let plotAmountFr=0


      if(this.unitname == 'Meter'){
         plotAmount = (this.plotareasqmtr ? this.plotareasqmtr : 0) * (this.rateperyard ? this.rateperyard : 0)
        this.plotValue = plotAmount

        plotAmountFr = (this.plotareasqmtr ? this.plotareasqmtr : 0) * (this.franchiserate ? this.franchiserate : 0)
        this.plotValueFr = plotAmountFr


      }
      else if(this.unitname == 'Feet'){
         plotAmount = (this.plotareasqfeet ? this.plotareasqfeet : 0) * (this.rateperyard ? this.rateperyard : 0)
        this.plotValue = plotAmount

        plotAmountFr = (this.plotareasqfeet ? this.plotareasqfeet : 0) * (this.franchiserate ? this.franchiserate : 0)
        this.plotValueFr=  plotAmountFr


      }
      else{

        plotAmount = (this.plotareayd ? this.plotareayd : 0) * (this.rateperyard ? this.rateperyard : 0)
        this.plotValue = plotAmount

        plotAmountFr = (this.plotareayd ? this.plotareayd : 0) * (this.franchiserate ? this.franchiserate : 0)
        this.plotValueFr= plotAmountFr
      }

      const cornerPhaseValue = this.cornerphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmount * this.cornerphasevalue) ? parseFloat(plotAmount * this.cornerphasevalue) : 0) / 100) :0
      const gardenPhaseValue = this.gardenphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmount * this.gardenphasevalue) ? parseFloat(plotAmount * this.gardenphasevalue) : 0) / 100) : 0

       const cornerPhaseValueFr = this.cornerphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmountFr * this.cornerphasevalue) ? parseFloat(plotAmountFr * this.cornerphasevalue) : 0) / 100) :0
      const gardenPhaseValueFr = this.gardenphase == 'Yes' && this.plccharges == 'Yes' ? (((plotAmountFr * this.gardenphasevalue) ? parseFloat(plotAmountFr * this.gardenphasevalue) : 0) / 100) :0


      this.plotamount = plotAmount + (cornerPhaseValue? cornerPhaseValue:0) + (gardenPhaseValue? gardenPhaseValue:0)
      this.plotamount = this.handleRoundOff(this.plotamount)

      this.plotAmountFr = plotAmountFr + (cornerPhaseValueFr? cornerPhaseValueFr:0) + (gardenPhaseValueFr?gardenPhaseValueFr:0)
      this.plotAmountFr = this.handleRoundOff(this.plotAmountFr)
      this.handleAmount()
      this.plotValue = isNaN(this.plotValue) ? 0 : this.handleRoundOff(this.plotValue)
      this.plotValueFr =isNaN(this.plotValueFr)?0:this.handleRoundOff(this.plotValueFr)



      this.gardenAmount= this.handleRoundOff(gardenPhaseValue)
      this.cornerAmount = this.handleRoundOff(cornerPhaseValue)

       this.gardenAmountFr= this.handleRoundOff(gardenPhaseValueFr)
      this.cornerAmountFr= this.handleRoundOff(cornerPhaseValueFr)

    },
    handleUpdatedAmount(index) {
      if (index == this.rateLength) {
        this.plotamount = this.updatedrate[index].amount;
      }
    },
    handleAmount() {
      if(this.updatedrate[this.updatedrate.length-1]?.amount !== this.plotamount){
        if (this.updatedrate.length == this.rateLength) {
          this.updatedrate.push({
            date: moment(new Date()).format("DD/MM/YYYY"),
            amount: "",
          });
        }

        if (!this.$route.params.id) {
          this.updatedrate[0].amount = this.plotamount;
        } else {
          this.updatedrate[this.rateLength].amount = this.plotamount;
        }
      }
      if(this.updatedrate[this.updatedrate.length-1]?.amount == this.updatedrate[this.updatedrate.length-2]?.amount){
        this.updatedrate.splice(this.updatedrate.length-1,1)
      }

    },
    cornerhandelclick(e) {
      if(e == 'No'){
        this.cornerphasevalue = null
        this.cornerAmount = null
      }
      this.cornerValue = e;
      this.totalAmountcal();
    },
    gardenhandelclick(e) {
      if(e == 'No'){
        this.gardenphasevalue = null
        this.gardenAmount = null
        this.gardenAmountFr = null

      }
      this.gardenValue = e;
      this.totalAmountcal();

    },
    totalProjectMtr(e) {
      if (e == "projectArea") {
        this.plotareayd =this.handleRoundOff(
          (parseFloat(this.plotareasqmtr) * 1.19));
           this.plotareasqfeet =this.handleRoundOff(
          (parseFloat(this.plotareasqmtr)) /  0.093370682);
      }

      if (e == "superBuildArea") {
        this.superarea_sqyds =this.handleRoundOff(
          (parseFloat(this.superarea_sqmt) * 1.19));
          this.superarea_sqfeet =this.handleRoundOff(
          (parseFloat(this.superarea_sqmt)/  0.093370682));
      }

      if (e == "carpetArea") {
        this.carpetarea_sqyds =this.handleRoundOff(
          (parseFloat(this.carpetarea_sqmt) * 1.19));
          this.carpetarea_sqfeet =this.handleRoundOff(
          (parseFloat(this.carpetarea_sqmt)/  0.093370682));
      }
      this.totalAmountcal()



    },
    totalProjectYd(e) {
      this.handelDevelopment()
      if (e == "projectArea") {
        this.plotareasqmtr =this.handleRoundOff(
          (parseFloat(this.plotareayd)/ 1.19));
        this.plotareasqfeet=this.handleRoundOff(
          (parseFloat(this.plotareayd))*9);

      }
      if (e == "superBuildArea") {
        this.superarea_sqmt =this.handleRoundOff(
          (parseFloat(this.superarea_sqyds) / 1.19));
          this.superarea_sqfeet =this.handleRoundOff(
          (parseFloat(this.superarea_sqyds)* 9));
      }

     if (e == "carpetArea") {
        this.carpetarea_sqmt =this.handleRoundOff(
          (parseFloat(this.carpetarea_sqyds) / 1.19));
          this.carpetarea_sqfeet =this.handleRoundOff(
          (parseFloat(this.carpetarea_sqyds) * 9));
      }
      this.totalAmountcal()
    },
    totalProjectFeet(e) {
      if (e == "projectArea") {
        this.plotareasqmtr =this.handleRoundOff(
          (parseFloat(this.plotareasqfeet) * 0.093370682));
          this.plotareayd =this.handleRoundOff(
          (parseFloat(this.plotareasqfeet) /9));
      }
      if (e == "superBuildArea") {
        this.superarea_sqmt =this.handleRoundOff(
          (parseFloat(this.superarea_sqfeet) * 0.093370682));
           this.superarea_sqyds =this.handleRoundOff(
          (parseFloat(this.superarea_sqfeet) / 9));
      }

     if (e == "carpetArea") {
        this.carpetarea_sqmt =this.handleRoundOff(
          (parseFloat(this.carpetarea_sqfeet) * 0.093370682));
          this.carpetarea_sqyds =this.handleRoundOff(
          (parseFloat(this.carpetarea_sqfeet) / 9));
      }

        this.totalAmountcal()
    },

    handleRoundOff(num){
      return num ? parseFloat(num.toFixed(2)):0
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getPlotById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      data.map((item) => {
        this.superarea_sqfeet=item.superarea_sqfeet;
        this.carpetarea_sqfeet=item.carpetarea_sqfeet;
        this.placefacing=item.placefacing;
        this.plotValue=item.plotValue;
        this.development=item.development;
        this.electricitycharges=item.electricitycharges;
        this.months=item.months;
        this.unitname = item.unitname;
        this.customer_plotrate = item.customer_plotrate;
        this.oldStatus = item.status


        this.plotno = item.plotno;
        this.phase = item.phasename;
        this.project = item.projectname;
        this.plotareasqmtr = item.plotareasqmtr;
        this.plotareayd = item.plotareayd;
        this.plotareasqfeet = item.plotareasqfeet;
        this.superarea_sqmt = item.superarea_sqmt;
        this.superarea_sqyds = item.superarea_sqyds;
        this.totalarea_sqmt = item.totalarea_sqmt;
        this.totalarea_sqyds = item.totalarea_sqyds;
        this.carpetarea_sqmt = item.carpetarea_sqmt;
        this.carpetarea_sqyds = item.carpetarea_sqyds;
        this.length = item.length;
        this.width = item.width;
        this.rateperyard = item.rateperyd;
        this.plotamount = item.plotamount;
        this.developmentcharge = item.developmentcharge;
        this.status = item.status;
        this.direction = item.direction;
        this.gardenphase = item.gardenphase;
        this.gardenphasevalue = item.gardenphasevalue;
        this.cornerphase = item.cornerphase;
        this.cornerphasevalue = item.cornerphasevalue;
        this.oldSurveyNo = item.oldsurveyno;
        this.North = item.pnorth;
        this.South = item.psouth;
        this.East = item.peast;
        this.West = item.pwest;
        this.documents = item.plotattachment;
        this.updatedrate = item.updatedrate ? item.updatedrate : [];
        this.rateLength = this.updatedrate.length;
        this.totalmaintainance=item.total_maintenance_charge

        this.clubHouse=item.clubHouse;
        this.maintainancecharge=item.maintainancecharge;
        this.otherextracharges=item.otherextracharges;
        this.plccharges=item.plccharges;
        this.extraamount=item.extraamount;
        this.withdevelopment=item.withdevelopment;
        this.plotValue=item.plotValue;
        this.franchiserate =item.franchiserate
        this.East=item.peast

        this.setProject(item.project_id);
        this.clubhouse_d = this.clubHouse && this.clubHouse!=='0.00'?'Yes':'No'
        this.maintanance_d = this.maintainancecharge && this.maintainancecharge!=='0.00'?'Yes':'No'
        this.othercharges_d = this.otherextracharges && this.otherextracharges!=='0.00'?'Yes':'No'
        this.electricity_d =this.electricitycharges && this.electricitycharges!=='0.00'?'Yes':'No'
        this.withdevelopment= this.development && this.development!=='0.00'?'No':'Yes'
        this.plccharges =(this.cornerphasevalue && this.cornerphasevalue!=='0.00') || (this.cornerphasevalue && this.cornerphasevalue!=='0.00') ?'Yes':'No'
        this.handelDevelopment()
        this.totalAmountcal()

        this.plotAmountFr=item.plotAmountFr
        this.plotValueFr=item.plotValueFr
        this.gardenAmountFr=item.gardenAmountFr
        this.cornerAmountFr = item.cornerAmountFr
        this.remarks = item.remarks
      });
    },
    checkStatus() {
      if (this.ifEdit) {
        if (this.oldStatus.toLowerCase() == 'booked' || this.oldStatus.toLowerCase() == 'sold' || this.oldStatus.toLowerCase() == 'registered') {
          if (this.status.toLowerCase() == 'vacant' || this.status.toLowerCase() == 'hold') {
            this.$swal({
              title: "Are you sure?",
              text: `This plot is already ${this.oldStatus} do you want to change status to ${this.status}`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, update it!",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            })
              .then((result) => {
                if (result.value) {
                  this.submitForm()
                }
              })
          }
          else {
            this.submitForm()
          }
        }
        else {
          this.submitForm()
        }
      }
      else {
        this.submitForm()
      }
    },
    submitForm(e) {
      // this.East= {
      //   superarea_sqfeet:this.superarea_sqfeet,
      //   carpetarea_sqfeet:this.carpetarea_sqfeet,
      //   placefacing:this.placefacing,
      //   plotValue:this.plotValue,
      //   development:this.development,
      //   electricitycharges:this.electricitycharges,
      //   months:this.months,
      //   unitname:this.unitname

      // }
      var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        const data = {
          branch: 1,
          company: 1,
          active: 1,
          project_id: this.project.id,
          plotno: this.plotno,
          plotname: this.plotno,
          phaseno: this.phase,
          plotareasqmtr: this.plotareasqmtr,
          plotareayd: this.plotareayd,
          plotareasqfeet: this.plotareasqfeet,
          superarea_sqmt: this.superarea_sqmt,
          superarea_sqyds: this.superarea_sqyds,
          carpetarea_sqmt:this.carpetarea_sqmt,
          carpetarea_sqyds: this.carpetarea_sqyds,
          remarks:this.remarks,
          length: this.length,
          width: this.width,
          rateperyd: this.rateperyard,
          customer_plotrate:this.customer_plotrate,
          gardenphase: this.gardenphase,
          gardenphasevalue: this.gardenphasevalue,
          cornerphase: this.cornerphase,
          cornerphasevalue: this.cornerphasevalue,
          plotamount: this.plotamount,
          developmentcharge: this.developmentcharge,
          status: this.status,
          direction: this.direction,
          // oldsurveyno: this.oldSurveyNo,
          pnorth: this.North,
          psouth: this.South,
          pwest: this.West,
          data: this.documents,
          updatedrate: this.updatedrate,
          clubHouse:this.clubHouse,
          maintainancecharge:this.maintainancecharge,
          otherextracharges:this.otherextracharges,
          extraamount:this.extraamount,
          total_maintenance_charge:this.totalmaintainance,
          superarea_sqfeet:this.superarea_sqfeet,
          carpetarea_sqfeet:this.carpetarea_sqfeet,
          placefacing:this.placefacing,
          plotValue:this.plotValue,
          development:this.development,
          electricitycharges:this.electricitycharges,
          months:this.months,
          unitname:this.unitname,
          totalarea_sqyds:0,
          totalarea_sqmt:0,
          franchiserate: this.franchiserate,
          plotAmountFr:this.plotAmountFr,
          plotValueFr:this.plotValueFr,
          gardenAmountFr:this.gardenAmountFr,
          cornerAmountFr:this.cornerAmountFr,
        };
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/plot/${this.$route.params.id}`
              : `${baseApi}/plot`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/master/crm/plotmaster");
            })
            .catch((error) => {
              this.flag = false;

              let msg;
              if (error.response.data.status == "409") {
                msg = error.response.data.errors;
              }
              this.$swal({
                title: "Error!",
                text: msg ? msg : error.response.data.message,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },

    handelclickproject(e) {
      this.phase = e.phasename;
    },
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    addRow() {
      this.documents.push({
        name: "",
        attachment: "",
      });
    },
    removeRow(index) {
      this.documents.splice(index, 1);
    },
    addRowPlot() {
      this.updatedrate.push({
        date: moment(new Date()).format("DD/MM/YYYY"),
        amount: "",
      });
    },
    removeRowPlot(index) {
      this.updatedrate.splice(index, 1);
      this.plotamount = this.updatedrate[this.rateLength - 1].amount;
    },
    async handleChange(e, type, index) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.documents[index].attachment =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => {
          this.flag = false;
          console.log(error, "error");
        });
    },

    onClickBack() {
      this.$router.push("/master/crm/plotmaster");
    },
    setProject(id) {
      this.projectOption.map((item) => {
        if (item.id == id) {
          this.project = item;
          this.$forceUpdate()
        }
      });
    },
  },
};
</script>
